<template>
	<v-dialog :value="show" width="900" persistent>
		<v-card>
			<v-card-title class="subtitle-1 border-bottom">
				<span>Выбрать дома</span>
				<v-spacer></v-spacer>
				<v-btn icon @click="close">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>

			<v-card-text class="pa-0">
				<v-data-table
					v-model="selected"
					:headers="headers"
					:items="computedHouses"
					:loading="loading"
					item-key="_id"
					class="elevation-0 no-pagination"
					show-select
					fixed-header
					height="500px"
					hide-default-footer
					:items-per-page="-1"
					@click:row="handleSelect"
					@item-selected="handleSelect"
					@toggle-select-all="toggleSelectAll"
				>
					<template v-slot:[`body.prepend`]>
						<tr>
							<td></td>
							<td></td>
							<td>
								<TextField :value.sync="filter.apartment_address"></TextField>
							</td>
							<td>
								<TextField :value.sync="filter.name"> </TextField>
							</td>
							<td>
								<Select
									:value.sync="filter.category_residential_complex_id"
									:items="catalog.category_residential_complex"
									item-text="name"
									item-value="id"
									clearable
								></Select>
							</td>
							<td>
								<TextField :value.sync="filter.year_construction"></TextField>
							</td>
						</tr>
					</template>

					<template v-slot:[`item.category_residential_complex_id`]="{ item }">
						<td>
							{{ getCategory(item) }}
						</td>
					</template>
				</v-data-table>
			</v-card-text>

			<v-card-actions>
				<Button color="primary" text @click.native="clear">
					Снять выбранные
				</Button>
				<v-spacer></v-spacer>
				<Button color="primary" @click.native="close">
					Выбрать дома
				</Button>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	name: "AnalyticHousesModal",

	props: {
		show: {
			type: Boolean,
			required: true,
		},

		value: {
			type: Array,
			required: false,
		},
	},

	data() {
		return {
			loading: false,
			filter: {
				name: "",
				apartment_address: "",
				category_residential_complex_id: "",
				request_count: "",
			},
			headers: [
				{
					text: "№",
					value: "index",
					width: "70px",
				},
				{
					text: "Адрес",
					value: "apartment_address",
					filter: v => {
						const { apartment_address } = this.filter;
						if (!apartment_address) return true;
						if (!v) return false;
						return v.toLowerCase().includes(apartment_address.toLowerCase());
					},
				},
				{
					text: "Название ЖК",
					value: "name",
					filter: v => {
						const { name } = this.filter;
						if (!name) return true;
						if (!v) return false;
						return v.toLowerCase().includes(name.toLowerCase());
					},
				},
				{
					text: "Категория ЖК",
					value: "category_residential_complex_id",
					filter: v => {
						const id = this.filter.category_residential_complex_id;
						if (!id) return true;
						if (!v) return false;
						return v === id;
					},
				},
				{
					text: "Год постройки",
					value: "year_construction",
					filter: v => {
						const { year_construction } = this.filter;
						if (!year_construction) return true;
						if (!v) return false;
						return v.startsWith(year_construction);
					},
				},
			],
			selected: [],
		};
	},

	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
			houses: "ksk/GET_HOUSES",
		}),
		computedHouses() {
			if (!this.houses) return [];
			return this.houses.map((e, i) => ({ ...e, index: i + 1 }));
		},
	},

	watch: {
		value: {
			handler() {
				this.convert();
			},
			deep: true,
		},
	},

	methods: {
		clear() {
			this.selected = [];
			this.update();
		},

		close() {
			this.$emit("update:show", false);
		},

		convert() {
			this.selected = this.value.map(e =>
				this.computedHouses.find(h => e._id === h._id)
			);
		},

		getCategory({ category_residential_complex_id }) {
			return (
				this.catalog.category_residential_complex.find(
					h => h.id === category_residential_complex_id
				)?.name || ""
			);
		},

		handleSelect(e) {
			const house = e.item ? e.item : e;
			const index = this.selected.findIndex(h => h._id === house._id);
			if (index !== -1) this.selected.splice(index, 1);
			else this.selected.push(house);
			this.update();
		},

		toggleSelectAll(e) {
			const arr = e.value ? e.items : [];
			this.selected = arr;
			this.update();
		},

		update() {
			console.log(this.selected);
			const arr = this.selected.map(e => ({
				_id: e._id,
				apartment_address: e.apartment_address,
				index: e.index,
			}));
			this.$emit("update:value", arr);
		},
	},

	created() {
		if (this.value.length) {
			this.convert();
		}
	},
};
</script>

<style lang="scss" scoped></style>
