<template>
	<v-card class="analytic-sidebar__card mb-6">
		<v-card-title
			class="font-weight-light text-h6 border-bottom py-3"
			:class="titleClass"
		>
			<slot name="title">
				{{ title }}
			</slot>
		</v-card-title>
		<v-card-text class="pt-4">
			<slot></slot>
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	name: "AnalyticSidebarCard",

	props: {
		title: {
			type: String,
			default: "Title",
		},
		titleClass: {
			type: String,
			default: "",
		},
	},
};
</script>
