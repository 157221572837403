<template>
	<div class="d-flex justify-space-between align-center" v-bind="$attrs">
		<h6
			v-if="name"
			class="name subtitle-1 font-weight-light text-none custom-text-capitalize"
		>
			{{ name }}
		</h6>
		<div class="linebar d-flex flex-column flex-grow-1">
			<h5
				class="d-flex justify-space-between flex-grow-1 text-h5 font-weight-light"
			>
				<div class="d-flex align-end">
					<strong class="mr-3">{{ value }}</strong>
					<span
						v-if="label"
						class="subtitle-1 text-lowercase font-weight-light"
					>
						{{ label }}
					</span>
				</div>
				<strong>{{ percent }}%</strong>
			</h5>
			<v-progress-linear
				class="flex-grow-1"
				color="amber"
				rounded
				:value="percent"
			></v-progress-linear>
		</div>
	</div>
</template>

<script>
export default {
	name: "Linebar",

	props: {
		value: {
			type: [Number, String],
			default: 0,
		},
		percent: {
			type: [Number, String],
			default: 0,
		},
		label: {
			type: String,
			default: "",
		},
		name: {
			type: String,
			default: "",
		},
	},
};
</script>

<style lang="scss" scoped>
.name {
	padding-right: 10px;
	overflow-wrap: break-word;
	width: 200px;
}
</style>
