<template>
	<AnalyticSidebarCard title="Категории">
		<v-checkbox
			v-model="checked"
			v-for="r of categories"
			class="mt-0 mb-2"
			multiple
			hide-details
			:value="r.id"
			:key="r.id"
			:label="r.name"
		></v-checkbox>

		<v-checkbox
			:input-value="checkboxAll"
			:label="checkboxLabel"
			:indeterminate="isIndeterminate"
			hide-details
			@change="handleChange"
		></v-checkbox>
	</AnalyticSidebarCard>
</template>

<script>
import AnalyticSidebarCard from "@/components/AnalyticSidebarCard";
import { mapGetters } from "vuex";

export default {
	name: "AnalyticSidebarCardCategory",

	components: {
		AnalyticSidebarCard,
	},

	props: {
		value: {
			type: Array,
			required: true,
		},
	},

	data() {
		return {
			checkboxAll: false,
			checked: [],
		};
	},

	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
		}),

		categories() {
			return this.catalog.request_categories;
		},

		checkboxLabel() {
			return this.value.length < 16 ? "Отметить все" : "Отменить выбор";
		},

		isIndeterminate() {
			const ln = this.value.length;
			if (ln > 0 && this.categories.length > ln) {
				this.checkboxAll = false;
				return true;
			}
			return false;
		},
	},

	watch: {
		checked() {
			this.update();
		},
	},

	methods: {
		handleChange() {
			const ln = this.checked.length;
			if ((ln && this.isIndeterminate) || !ln) {
				this.checkboxAll = true;
				this.checked = this.categories.map(e => e._id);
			} else {
				this.checkboxAll = false;
				this.checked = [];
			}
		},

		update() {
			this.$emit("update:value", this.checked);
		},
	},

	created() {
		this.checked = [...this.value];
	},
};
</script>

<style></style>
