<template>
	<aside class="analytic-sidebar">
		<AnalyticSidebarCard title="Период">
			<Select :value.sync="data.period" :items="periodItems" />
			<v-divider v-if="data.period === 'range'" class="my-4"></v-divider>
			<DatePickerPlain
				v-if="data.period === 'range'"
				:date.sync="data.date_first"
				nudge-left="30px"
			/>
			<div class="py-1"></div>
			<DatePickerPlain
				v-if="data.period === 'range'"
				:date.sync="data.date_second"
				nudge-left="30px"
			/>
		</AnalyticSidebarCard>

		<AnalyticSidebarCard v-if="showPaid" title="Платность">
			<Select :value.sync="data.price" :items="paidItems" />
		</AnalyticSidebarCard>

		<AnalyticSidebarCardCategory
			v-if="showCategories"
			:value.sync="data.category_id"
		/>

		<AnalyticSidebarCard title="Дома" title-class="primary white--text">
			<Button block @click.native="showHousesModal = true">Выбрать дома</Button>
			<legend class="subtitle-2 font-weight-light my-2">
				Выбрано домов {{ data.house_ids.length }}
			</legend>
			<v-chip-group column>
				<v-chip
					v-for="(house, i) of data.house_ids"
					:key="house._id"
					close
					@click:close="removeHouse(i)"
				>
					<span class="text-truncate">
						{{ `№${house.index}, ${house.apartment_address}` }}
					</span>
				</v-chip>
			</v-chip-group>
		</AnalyticSidebarCard>

		<AnalyticHousesModal
			:show.sync="showHousesModal"
			:value.sync="data.house_ids"
		/>
	</aside>
</template>

<script>
import AnalyticHousesModal from "@/components/AnalyticHousesModal";
import AnalyticSidebarCard from "@/components/AnalyticSidebarCard";
import AnalyticSidebarCardCategory from "@/components/AnalyticSidebarCardCategory";
import DatePickerPlain from "@/components/app/DatePickerPlain";
import Select from "@/components/app/Select";

export default {
	name: "AnalyticRequisitionsSidebar",

	components: {
		AnalyticHousesModal,
		AnalyticSidebarCard,
		AnalyticSidebarCardCategory,
		DatePickerPlain,
		Select,
	},

	props: {
		showHouses: {
			type: Boolean,
			default: true,
		},
		showCategories: {
			type: Boolean,
			default: false,
		},
		showPaid: {
			type: Boolean,
			default: true,
		},
	},

	data() {
		return {
			data: {
				period: null,
				price: null,
				house_ids: [],
				category_id: [],
				date_first: "",
				date_second: "",
			},
			showHousesModal: false,
			timer: null,

			periodItems: [
				{ text: "За все время", value: null },
				{ text: "За год", value: "year" },
				{ text: "За месяц", value: "month" },
				{ text: "За неделю", value: "week" },
				{ text: "За день", value: "day" },
				{ text: "За период", value: "range" },
			],

			paidItems: [
				{ text: "Все", value: null },
				{ text: "Платные", value: "1" },
				{ text: "Бесплатные", value: "0" },
			],
		};
	},

	watch: {
		data: {
			handler(obj) {
				clearTimeout(this.timer);
				this.timer = setTimeout(() => {
					this.$store.commit("ksk/SET_ANALYTIC_PARAMS", this.data);
					this.$store.dispatch("ksk/load_analytic");
				}, 500);

				// * Uncomment if query changes are needed
				// const query = {};
				// for (const [key, val] of Object.entries(obj)) {
				// 	if (val) query[key] = val;
				// }
				// this.$router.replace({ query });
			},
			deep: true,
		},
	},

	methods: {
		removeHouse(i) {
			this.data.house_ids.splice(i, 1);
		},
	},

	mounted() {},
};
</script>

<style></style>
